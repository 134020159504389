import React from 'react'
import * as Yup from 'yup'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import { useMutation } from '@apollo/react-hooks'
import { renameFolderMutation } from '../../graphql/userLibrary'
import CredentialsForm from '../CredentialsForm'

const RenameDialog = ({ open, refetch, onClose, folder }) => {
  const inputFields = [{ label: 'Name', name: 'newName', type: 'text' }]
  const [renameFolder, { loading }] = useMutation(renameFolderMutation, {
    onCompleted: () => {
      refetch()
      onClose()
    },
    awaitRefetchQueries: true
  })
  const validationSchema = Yup.object().shape({
    newName: Yup.string().required('Name is required')
  })
  const initialValues = {
    newName: ''
  }

  const handleSubmit = async (values) => {
    await renameFolder({
      variables: {
        folderPath: folder.path,
        ...values
      }
    })
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Rename Folder</DialogTitle>
      <DialogContent>
        <CredentialsForm
          inputFields={inputFields}
          validationSchema={validationSchema}
          initialValues={initialValues}
          handleSubmit={handleSubmit}
          submitText='Confirm'
          loading={loading}
        />
      </DialogContent>
    </Dialog>
  )
}

export default RenameDialog
