import React, { useState } from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import Grid from '@material-ui/core/Grid'

import GridViewThumbnail from '../GridViewThumbnail'
import {
  OPEN_CONFIRM_DELETE,
  UNSELECT_ITEM,
  SELECT_ITEM
} from '../../providers/UserLibraryProvider/actions-types'
import * as actions from '../../providers/UserProjectsProvider/action-types'
import { renderFileSrc, getFileType } from '../../utils/helpers'
import UserTemplate from '../UserTemplate'
import RenameDialog from '../RenameDialog'

const LibraryFilesGrid = ({
  files,
  folders,
  loading,
  selectedItems,
  trashItems,
  dispatch,
  type,
  handleDirectoryChange,
  currentDirectory,
  libraryType,
  refetch
}) => {
  const [renameDialog, setRenameDialog] = useState({
    open: false,
    folder: null
  })
  const handleEdit = (folder) => {
    if (libraryType === 'userProjects') setRenameDialog({ open: true, folder })
  }

  /**
   * remove undefined values from folers array
   */
  if (folders) {
    // eslint-disable-next-line no-param-reassign
    folders = folders.filter(function (folder) {
      return folder !== undefined
    })
  }

  const renderFolders =
    folders &&
    folders.map(({ id, path, name, size, lastModified }) => (
      <GridViewThumbnail
        hideActions={
          currentDirectory.type === 'businesses' || name === 'drafts'
        }
        key={path}
        name={name}
        lastModified={lastModified}
        size={size}
        path={path}
        type='folder'
        previewUrl={renderFileSrc('folder')}
        selected={
          path.includes('.trash')
            ? trashItems.find((i) => i.path === path)
            : selectedItems.find((i) => i.path === path)
        }
        onDelete={() =>
          dispatch({
            type:
              libraryType === 'userProjects'
                ? actions.OPEN_CONFIRM_DELETE
                : OPEN_CONFIRM_DELETE,
            payload: [{ id, path, size, type: 'folder' }]
          })
        }
        onDoubleClick={() => handleDirectoryChange(path, name, id, type)}
        // onOpen={() => handleDirectoryChange(path, name, id, type)}
        onSelectChange={(selected) => {
          dispatch({
            type:
              libraryType === 'userProjects'
                ? selected
                  ? actions.UNSELECT_ITEM
                  : actions.SELECT_ITEM
                : selected
                ? UNSELECT_ITEM
                : SELECT_ITEM,
            payload: { id, path, size, type: 'folder' }
          })
        }}
        onEdit={() => handleEdit({ id, path, name, size, lastModified })}
        libraryType={libraryType}
      />
    ))

  const renderFiles =
    files &&
    files.map((file) => {
      const { path, size, extension } = file
      if (libraryType === 'userProjects') {
        const { template } = file
        return (
          <UserTemplate
            key={template?.id}
            data={template}
            dispatch={dispatch}
            disableActions
            onSelectChange={(selected) =>
              dispatch({
                type:
                  libraryType === 'userProjects'
                    ? selected
                      ? actions.UNSELECT_ITEM
                      : actions.SELECT_ITEM
                    : selected
                    ? UNSELECT_ITEM
                    : SELECT_ITEM,
                payload: {
                  path,
                  size,
                  type:
                    template.type.typeName === 'UserImageTemplate'
                      ? 'image'
                      : 'video',
                  status: template.currentStatus.status
                }
              })
            }
            selected={selectedItems.find((i) => i.path === path)}
            refetch={refetch}
          />
        )
      }
      const { name, lastModified, url } = file
      return (
        <GridViewThumbnail
          key={path}
          name={name}
          lastModified={lastModified}
          size={size}
          path={path}
          previewUrl={renderFileSrc(
            getFileType(extension.replace(/\./g, '')),
            url
          )}
          selected={
            path.includes('.trash')
              ? trashItems.find((i) => i.path === path)
              : selectedItems.find((i) => i.path === path)
          }
          onDelete={() =>
            dispatch({
              type:
                libraryType === 'userProjects'
                  ? actions.OPEN_CONFIRM_DELETE
                  : OPEN_CONFIRM_DELETE,
              payload: [
                { path, size, type: getFileType(extension.replace(/\./g, '')) }
              ]
            })
          }
          onSelectChange={(selected) =>
            dispatch({
              type:
                libraryType === 'userProjects'
                  ? selected
                    ? actions.UNSELECT_ITEM
                    : actions.SELECT_ITEM
                  : selected
                  ? UNSELECT_ITEM
                  : SELECT_ITEM,
              payload: {
                path,
                size,
                type: getFileType(extension.replace(/\./g, ''))
              }
            })
          }
        />
      )
    })

  return (
    <>
      {!loading ? (
        <Grid container spacing={1}>
          {renderFolders}
          {renderFiles}
        </Grid>
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={6} sm={4} md={3} xl={2}>
            <Skeleton variant='rect' height={180} />
          </Grid>
          <Grid item xs={6} sm={4} md={3} xl={2}>
            <Skeleton variant='rect' height={180} />
          </Grid>
          <Grid item xs={6} sm={4} md={3} xl={2}>
            <Skeleton variant='rect' height={180} />
          </Grid>
          <Grid item xs={6} sm={4} md={3} xl={2}>
            <Skeleton variant='rect' height={180} />
          </Grid>
        </Grid>
      )}
      <RenameDialog
        open={renameDialog?.open}
        folder={renameDialog?.folder}
        onClose={() => setRenameDialog({ open: false, folder: null })}
        refetch={refetch}
      />
    </>
  )
}
export default LibraryFilesGrid
