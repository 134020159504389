import React from 'react'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

import Delete from '@material-ui/icons/Delete'
import EditLocation from '@material-ui/icons/EditLocation'
import RestoreFromTrash from '@material-ui/icons/RestoreFromTrash'

import {
  OPEN_CONFIRM_DELETE,
  OPEN_CONFIRM_MOVE,
  OPEN_CONFIRM_RESTORE
} from '../../providers/UserLibraryProvider/actions-types'

const UserLibrarySubActions = ({
  selectedItems,
  trashItems,
  dispatch,
  libraryType,
  showTrash
}) => {
  /*
    context
    */

  /*
    helpers
    */
  const isFolderSelected = selectedItems.some((item) => item.type === 'folder')
  const handleDelete = () => {
    dispatch({
      type: OPEN_CONFIRM_DELETE,
      payload: selectedItems
    })
  }

  const handleMove = () => {
    dispatch({
      type: OPEN_CONFIRM_MOVE,
      payload: selectedItems
    })
  }

  const handleRestore = () => {
    dispatch({
      type: OPEN_CONFIRM_RESTORE,
      payload: trashItems
    })
  }

  return (
    <Box display='flex' justifyContent='space-between'>
      <Box>
        <Button
          variant='outlined'
          color='primary'
          disabled={showTrash || !selectedItems.length}
          startIcon={<Delete />}
          onClick={handleDelete}
        >
          Delete
        </Button>
      </Box>

      {(libraryType === 'userLibrary' || libraryType === 'userProjects') && (
        <Box mx={1}>
          <Button
            variant='outlined'
            color='primary'
            disabled={showTrash || !selectedItems.length || isFolderSelected}
            startIcon={<EditLocation />}
            onClick={handleMove}
          >
            Move
          </Button>
        </Box>
      )}

      {libraryType === 'userLibrary' && (
        <Box>
          <Button
            variant='outlined'
            color='primary'
            disabled={!trashItems.length}
            startIcon={<RestoreFromTrash />}
            onClick={handleRestore}
          >
            Restore
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default UserLibrarySubActions
