import * as actions from './action-types'
import { userProjectToggle } from '../../lib/constants/userTemplate'

const reducer = (state, { type, payload }) => {
  switch (type) {
    case actions.SET_SEARCH:
      return {
        ...state,
        search: payload
      }
    case actions.SET_ORDER_BY:
      return {
        ...state,
        orderBy: payload
      }
    case actions.SET_TYPE:
      return {
        ...state,
        type: payload
      }
    case actions.SET_STATUSES:
      return {
        ...state,
        currentStatusValues: userProjectToggle[payload].values,
        currentStatusLabel: userProjectToggle[payload].label
      }

    case actions.CLEAR_SELECTED_ITEMS:
      return {
        ...state,
        selectedItems: []
      }

    case actions.SET_FAVORITES_LIST: {
      return {
        ...state,
        favoritesList: payload
      }
    }
    case actions.CHANGE_DIRECTORY_VIEW:
      return {
        ...state,
        directoryView: payload
      }
    case actions.CHANGE_SEARCH_TERM:
      return {
        ...state,
        search: payload
      }
    case actions.CHANGE_FILTER:
      return {
        ...state,
        filters: payload.split(',')
      }
    case actions.OPEN_CONFIRM_MOVE:
      return {
        ...state,
        confirmMove: true,
        moveDirectory: state.currentDirectory.path,
        selectedItems: payload
      }
    case actions.CLOSE_CONFIRM_MOVE:
      return {
        ...state,
        confirmMove: false
      }
    case actions.CHANGE_PROJECTS_MOVE_PATH:
      return {
        ...state,
        moveDirectory: payload
      }
    case actions.OPEN_CONFIRM_DELETE:
      return {
        ...state,
        confirmDelete: true,
        selectedItems: payload
      }
    case actions.OPEN_CONFIRM_RENAME:
      return {
        ...state,
        confirmRename: true,
        selectedItems: payload
      }
    case actions.CLOSE_CONFIRM_RENAME:
      return {
        ...state,
        confirmRename: false
      }
    case actions.OPEN_CONFIRM_RESTORE:
      return {
        ...state,
        confirmRestore: true,
        trashItems: payload
      }
    case actions.CLOSE_CONFIRM_RESTORE:
      return {
        ...state,
        confirmRestore: false
      }

    case actions.CLOSE_CONFIRM_DELETE:
      return {
        ...state,
        confirmDelete: false
      }
    case actions.CHANGE_CURRENT_DIRECTORY:
      return {
        ...state,
        currentDirectory: payload
      }
    case actions.SELECT_ITEM: {
      const items = payload?.path?.includes('.trash')
        ? state.trashItems
        : state.selectedItems
      const index = payload?.path?.includes('.trash')
        ? 'trashItems'
        : 'selectedItems'
      const other = index === 'trashItems' ? 'selectedItems' : 'trashItems'
      items.push(payload)
      return {
        ...state,
        [index]: items,
        [other]: []
      }
    }
    case actions.UNSELECT_ITEM: {
      const index = payload.path.includes('.trash')
        ? 'trashItems'
        : 'selectedItems'
      return {
        ...state,
        [index]: state[index].filter((i) => i.path !== payload.path)
      }
    }
    case actions.SET_DRAFTS:
      return {
        ...state,
        drafts: payload
      }
    case actions.SET_SHOW_TRASH:
      return {
        ...state,
        showTrash: payload
      }
    case actions.CLEAR_TRASH_ITEMS:
      return {
        ...state,
        trashItems: []
      }

    default:
      return state
  }
}

export default reducer
