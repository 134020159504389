const userLibraryState = {
  showTrash: false,
  directoryView: 'grid', // Or list
  currentDirectory: {
    id: '',
    name: '',
    path: '',
    type: 'folders',
    directoryData: []
  },
  selectedItems: [],
  trashItems: [],
  moveDirectory: '',
  sortQuery: 'lastModified_DESC',
  moveToPath: null,
  trash: false,
  search: '',
  confirmDelete: false,
  confirmRestore: false,
  filters: ['audio', 'images', 'videos']
}

export default userLibraryState
