import React, { useState, useContext } from 'react'
import Warning from '@material-ui/icons/Warning'
import { yellow } from '@material-ui/core/colors'
import { useMutation } from '@apollo/react-hooks'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useSnackbar } from 'notistack'
import { UserProjectsContext } from '../../providers/UserProjectsProvider'
import LibraryActions from '../LibraryActions'
import LibraryFiles from '../LibraryFiles'
import {
  projectsDeleteFilesMutation,
  projectsDeleteFoldersMutation
} from '../../graphql/userTemplates'
import * as actions from '../../providers/UserProjectsProvider/action-types'

const UserProjects = ({ user }) => {
  const [openAddFolder, setOpenAddFolder] = useState(false)

  const { dispatch, variablesState, projects } = useContext(UserProjectsContext)
  const { refetch, userProjects, loading } = projects
  const { enqueueSnackbar } = useSnackbar()
  const { currentDirectory, drafts, showTrash, selectedItems, trashItems } =
    variablesState

  const items = {
    type: 'folders',
    folders:
      userProjects?.folders?.length > 0
        ? drafts && !currentDirectory.path
          ? [
              userProjects.folders.find((folder) => folder.name === 'drafts'),
              ...userProjects.folders.filter(
                (folder) => folder.name !== 'drafts'
              )
            ]
          : userProjects.folders.filter((folder) => folder.name !== 'drafts')
        : [],
    files: userProjects && userProjects.files
  }

  const [deleteFiles, { loading: deleteFilesLoading }] = useMutation(
    projectsDeleteFilesMutation,
    {
      onCompleted: async () => {
        refetch()
        enqueueSnackbar('Delete Successful!', { variant: 'success' })
        dispatch({ type: actions.CLOSE_CONFIRM_DELETE })
        dispatch({ type: actions.CLEAR_SELECTED_ITEMS })
      },
      awaitRefetchQueries: true,
      onError: () => {
        enqueueSnackbar('Delete Failed!', { variant: 'error' })
        dispatch({ type: actions.CLOSE_CONFIRM_DELETE })
      }
    }
  )

  const [deleteFolders, { loading: deleteFoldersLoading }] = useMutation(
    projectsDeleteFoldersMutation,
    {
      onCompleted: async () => {
        refetch()
        enqueueSnackbar('Delete Successful!', { variant: 'success' })
        dispatch({ type: actions.CLOSE_CONFIRM_DELETE })
        dispatch({ type: actions.CLEAR_SELECTED_ITEMS })
      },
      awaitRefetchQueries: true,
      onError: () => {
        enqueueSnackbar('Delete Failed!', { variant: 'error' })
        dispatch({ type: actions.CLOSE_CONFIRM_DELETE })
      }
    }
  )
  const handleDelete = () => {
    const files = []
    const folders = []
    selectedItems.forEach((item) =>
      item.type !== 'folder' ? files.push(item) : folders.push(item)
    )
    if (files.length)
      deleteFiles({
        variables: {
          filePaths: files.map((file) => file.path)
        }
      })
    if (folders.length)
      deleteFolders({
        variables: {
          folderPaths: folders.map((folder) => folder.path)
        }
      })
  }

  return (
    <>
      {user.statuses.filter(({ name }) => name === 'IS_VERIFIED').length > 0 ? (
        <>
          <Box display='flex' justifyContent='space-between'>
            <LibraryActions
              addModalVisible={openAddFolder}
              addButtonName='ADD FOLDER'
              setAddModalVisible={setOpenAddFolder}
              currentDirectory={currentDirectory}
              refetch={refetch}
              libraryType='userProjects'
              showTrash={showTrash}
              items={items}
              selectedItems={selectedItems}
              trashItems={trashItems}
              dispatch={dispatch}
            />
          </Box>
          {!loading && (
            <LibraryFiles
              items={items}
              libraryType='userProjects'
              loading={loading}
              refetch={refetch}
              state={variablesState}
              dispatch={dispatch}
              deleteFilesLoading={deleteFilesLoading}
              deleteFoldersLoading={deleteFoldersLoading}
              handleDelete={handleDelete}
            />
          )}
          {loading && (
            <Box display='flex' justifyContent='center' alignItems='center'>
              <CircularProgress />
            </Box>
          )}
        </>
      ) : (
        <Box display='flex' p={5} justifyContent='center' alignItems='center'>
          <Warning style={{ fontSize: 50, color: yellow[700] }} />
          <Typography variant='h5'>Please verify your email first</Typography>
        </Box>
      )}
    </>
  )
}

export default UserProjects
